@import "@angular/material/prebuilt-themes/indigo-pink.css";

html,
body {
    height: 100vh;
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-container mat-card {
    width: 300px;
}

.init-body {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.init-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1
}

mat-card-header {
    margin-bottom: 20px;
}

.init-container mat-form-field {
    width: 300px;
    padding-right: 16px;
}

.init-container mat-slide-toggle {
    margin-left: 16px;
    padding-bottom: 24px;
}

.init-container mat-radio-group {
    display: block;
    padding-bottom: 20px;
}

.email-sent-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.email-sent-container mat-card {
    width: 300px;
}

.left-content {
    order: 0;
    /* Make sure left content appears first */
}

.right-content {
    display: flex;
    align-items: center;
    order: 2;
    /* Make sure right content appears last */
}

.fill-space {
    flex: 1;
    /* This will make the fill space take up remaining space */
}